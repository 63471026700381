var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("headbox", {
        attrs: { title: "关于我们", isbackPath: true, path: "person" }
      }),
      !_vm.loading
        ? _c("div", { staticClass: "profile" }, [
            _c("h2", { staticClass: "title" }, [
              _vm._v("江阴市" + _vm._s(_vm.GLOBAL) + "珠宝有限责任公司")
            ]),
            _c("p", { staticClass: "content" }, [
              _vm._v(
                " 江阴市" +
                  _vm._s(_vm.GLOBAL) +
                  '珠宝有限责任公司是以贵金属交易为主营业务的专业机构，主营"回收提纯"和"兑换销售"两项业务体系。 '
              )
            ]),
            _c("img", {
              attrs: { src: require("@/assets/images/about.png"), alt: "" }
            }),
            _c("p", { staticClass: "content" }, [
              _vm._v(
                " 江阴市" +
                  _vm._s(_vm.GLOBAL) +
                  '珠宝有限责任公司自创立至今，坚持"诚实、诚信、诚恳"的原则。在运营模式上为客户提供更加快捷的服务为根本，经过不断的努力改进，形成具有' +
                  _vm._s(_vm.GLOBAL) +
                  "特色的报价系统和客户定价、结价、提货和存货的服务模式。针对客户采用一站式服务，优化交易中的繁琐环节，使客户体验到便捷贴心的服务。 "
              )
            ]),
            _c("p", { staticClass: "content" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.GLOBAL) +
                  "以成为中国具有行业影响力的贵金属服务企业为宗旨，为国内贵金属销售与回购打造专业的交易平台，以便捷的交易方式和诚信真实的态度服务于广大的贵金属从业者！ "
              )
            ])
          ])
        : _vm._e(),
      _vm.loading
        ? _c(
            "center",
            { attrs: { id: "loadingbox" } },
            [_c("van-loading", { attrs: { size: "70px" } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }